<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left:20px; padding-right:20px" class="p-3">
          <template v-slot:headerTitle>
            <h4 class="card-title w-full d-flex justify-content-between">
              <div>
                <i class="fa-solid fa fa-graduation-cap" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
                {{cvCardTitle}}
              </div>
              <div>
                <b-button variant="primary" class="mr-2 mb-1" @click="tprepSubjectModuleAdd">{{cvbtnAddNew}}</b-button>
                <b-button variant="primary" class="mb-1" @click="goBack()">Go Back</b-button>
              </div>
            </h4>
            <div class="mt-2">
              <b-badge title="exam module count" class="tags mr-2 mb-2 font-weight-normal" variant="none">
                <span>{{ whereFilter.subject_name ? whereFilter.subject_name : "Total Modules: " }}:&nbsp; {{ count }}</span>
              </b-badge>
            </div>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="6" md="5" lg="5" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <b-col sm="6" md="4" lg="4" xl="4" class="mb-3">
                <select
                  v-model="whereFilter.subject_name"
                  type="text"
                  class="form-control"
                  id="validation_degree"
                  @change=TPrepsetSubjectModuleFilter
                >
                  <option v-for="(subModName, index) of subjectModuleIntName" :value="subModName.interest_name" :key="(index+1)">
                    {{subModName.interest_name}}
                  </option>
                </select>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="subjectModuleObjList && subjectModuleObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="subjectModuleObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(subject_module_name)="data">
                    {{ data.item.subject_module_name}} <br>
                    <b-button variant="mr-1 btn actionIcon font-size-20" @click="goToQuizDeails(data.item.app_id)" size="sm" v-if="!data.item.editable">
                      <i class="fa fa-eye" title="View Quiz Detail"></i>
                    </b-button>
                    <b-button variant="mr-1 btn actionIcon font-size-20" @click="tprepSubjectModuleEdit('MODAL', data.item)" size="sm" v-if="!data.item.editable"><i class="ri-ball-pen-fill"></i></b-button>
                    <b-button variant="mr-1 btn actionIcon font-size-20" @click="showSubjectModuleDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line"></i></b-button>
                    <b-button variant="mr-1 btn actionIcon font-size-20" @click="attachQuiz(data.item)" title="Attach quiz" size="sm"><i class="ri-add-box-fill"></i></b-button>
                    <b-button variant="mr-1 btn actionIcon font-size-20" @click="appResultList(data.item)" title="Analyze results" size="sm" v-if="data.item.app_id"><i class="ri-search-eye-line"></i></b-button>
                  </template>
                  <template v-slot:cell(deleted)="data">
                    <b-badge v-if="data.item.deleted === 0" class="pointer ml-1" variant="success mr-1 mb-1" size="sm">
                      Published
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm">
                      Unpublished
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm">
                      Deleted
                    </b-badge>
                    <br />
                    <b-button variant="light mr-1 mb-1" :disabled="data.item.deleted === 2" size="sm" @click="tprepSubjectModulePublishUnpublish(data.item)" v-if="!data.item.editable">
                      <i class="fa fa-toggle-off m-0 p-1" title="Unpublish" style="color:red"></i>
                    </b-button>
                    <b-button variant="light mr-1 mb-1" :disabled="data.item.deleted === 0" size="sm" @click="tprepSubjectModulePublishUnpublish(data.item)" v-if="!data.item.editable">
                      <i class="fa fa-toggle-on m-0 p-1" title="Publish" style="color:green"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="tprepSubjectModuleEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showSubjectModuleDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelSubjectModuleAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <TPrepSubjectModuleAdd :propOpenedInModal="true" @emitCloseSubjectModuleAddModal="closeSubjectModuleAddModal" :propAlreadyAttachedApps = "propAlreadyAttachedApps"/>
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="tprepSubjectModuleAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSubjectModuleAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelSubjectModuleEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <TPrepSubjectModuleEdit :propOpenedInModal="true" :propSubjectModuleObj="subjectModuleEditObj" @emitCloseSubjectModuleEditModal="closeSubjectModuleEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="tprepSubjectModuleEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeSubjectModuleEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelSubjectModuleDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelSubjectModuleDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="tprepSubjectModuleDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModalAttachQuiz" size="lg" title="Attach a Quiz to TestSeries Module" >
      <TPrepAttachQuiz :propSubjectModId = "propSubjectModId" :propAppId="propAppId" @emitCloseAttachQuizModal="closeAttachQuizModal" :propAlreadyAttachedApps="propAlreadyAttachedApps" :propSubjectModName="propSubjectModName"/>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { TPrepSubjectModules } from "../../../FackApi/api/TPrepSubjectModule.js"
import TPrepSubjectModuleAdd from "./TPrepSubjectModuleAdd"
import TPrepSubjectModuleEdit from "./TPrepSubjectModuleEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { User } from "../../../FackApi/api/user"
import { Interests } from "../../../FackApi/api/interest"
import TPrepAttachQuiz from "./TPrepAttachQuiz.vue"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "TPrepSubjectModuleList",
  components: {
    TPrepSubjectModuleAdd,
    TPrepSubjectModuleEdit,
    TPrepAttachQuiz
  },
  data () {
    return {
      apiName: "tprep_subject_module_list",
      cvCardTitle: "TestSeries Configuration",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit TestSeries Modules",
      cvAddModalHeader: "Add TestSeries Modules",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "TestSeries Modules Response",
      showModelSubjectModuleAdd: false,
      showModelSubjectModuleEdit: false,
      showModelSubjectModuleDelete: false,
      showModalAttachQuiz: false,
      cvbtnAttachQuiz: "Attach Quiz",
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Subject", key: "subject_name", class: "text-left align-text-top", sortable: true },
        { label: "Module", key: "subject_module_name", class: "text-left align-text-top", sortable: true },
        { label: "Status", key: "deleted", class: "text-left align-text-top", sortable: true }
      ],
      subjectModuleObjList: null,
      subjectModuleEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 200,
      delObj: null,
      subjectModuleName: [],
      subjectModuleIntName: [],
      subModName: "",
      InterestType: "SUBJ",
      count: "0",
      whereFilter: {
        subject_name: ""
      },
      propSubjectModId: "",
      propAppId: "",
      propAlreadyAttachedApps: [],
      propSubjectModName: ""
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    TPrepsubjectModuleFilter () {
      return this.$store.getters["TPrepSubjectModuleList/selectedTPrepSubjectModuleState"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    await this.getFiltersFromCache()
    this.tprepSubjectModuleList()

    if (this.InterestType == "SUBJ") {
      await this.userInterestList(this.InterestType)
    }
    this.interestList()
    socialvue.index()
  },
  methods: {
    /**
     * goto User Profile
     */
    goBack () {
      this.$router.back()
    },
    /**
     *
     * Redirect to goToQuizDeails
    */
    goToQuizDeails (app) {
      try {
        window.open(`/quiz_detail/${app}`)
      }
      catch (err) {
        console.error("Exception occurred at goToQuizDeails() and Exception:", err.message)
      }
    },
    /**
     * get data from store cache by this method
    */
    async getFiltersFromCache () {
      this.getTPrepSubjectModuleFilter()
    },
    /**
     * attachQuiz
     */
    async attachQuiz (sbjm) {
      this.showModalAttachQuiz = true
      this.propSubjectModId = sbjm.subject_module_id
      this.propAppId = sbjm.app_id
      this.propSubjectModName = sbjm.subject_module_name
    },
    /**
     * appResultList
     */
    appResultList (app) {
      if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
        this.$router.push(`/appResult_list/${app.app_id}`)
      }
    },
    /**
     * closeAttachQuizModal
     */
    async closeAttachQuizModal (appId) {
      console.log(this.subjectModuleObjList, appId)
      let attach = this.subjectModuleObjList.find(e => e.subject_module_id == this.propSubjectModId)
      attach.app_id = appId
      this.showModalAttachQuiz = false
      this.tprepSubjectModuleList()
    },
    /**
     * goToApps
     */
    async goToApps () {
      this.$router.push("/quiz_list")
    },
    /**
     * get Subject Module Filter
    */
    async getTPrepSubjectModuleFilter () {
      try {
        let storeDate = await this.TPrepsubjectModuleFilter
        if (storeDate) {
          this.whereFilter.subject_name = storeDate
        }
      }
      catch (err) {
        console.error("Exception occurred at getTPrepSubjectModuleFilter() and Exception:", err.message)
      }
    },
    /**
     * set Subject Module Filter
    */
    async TPrepsetSubjectModuleFilter () {
      this.$store.commit("TPrepSubjectModuleList/addTPrepSubjectModule", this.whereFilter.subject_name)
      this.tprepSubjectModuleList()
      // todo: save selected subject in store
    },
    /**
     * tprepSubjectModuleList
     */
    async tprepSubjectModuleList () {
      try {
        let subjectModuleListResp = await TPrepSubjectModules.tprepSubjectModuleList(this, this.whereFilter)
        if (subjectModuleListResp.resp_status) {
          this.subjectModuleObjList = subjectModuleListResp.resp_data.data
          this.totalRows = subjectModuleListResp.resp_data.count
          this.count = subjectModuleListResp.resp_data.count
          this.propAlreadyAttachedApps = this.subjectModuleObjList.map(e => e.app_id)
        }
        else {
          this.subjectModuleObjList = []
          this.toastMsg = subjectModuleListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at tprepSubjectModuleList() and Exception:", err.message)
      }
    },
    /**
     * userinterestList
     */
    async userInterestList (interestType) {
      this.loading = true
      try {
        let userInterestListResp = await User.userInterestList(this, this.userData.user_id, interestType)
        if (userInterestListResp.resp_status) {
          this.userInterestObjList = userInterestListResp.resp_data.interest_list
          this.subjectModuleName = this.userInterestObjList.INTERESTCAT11117
        }
        else {
          if (!this.askedForUserInterest && this.userData.user_mobile_verified && Number(this.userData.user_mobile_verified) === 1) {
            this.$emit("emitUserInterestsNotAdded", true)
            await this.$store.dispatch("User/setAskedForUserInterest", true)
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at interestList() and Exception:", err.message)
      }
      this.loading = false
    },
    /**
     * Publish/UnPublish tprepSubjectModule
     */
    async tprepSubjectModulePublishUnpublish (sbjm) {
      // sbjm <=> subject module
      console.log({ sbjm })
      let payload = {
        subject_module_id: sbjm.subject_module_id,
        deleted: null
      }

      if (sbjm.deleted === 0) {
        // sbjm is published then unpublish
        payload.deleted = 2
      }
      else if (sbjm.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }

      let sbjmResp = await TPrepSubjectModules.tprepSubjectModuleEdit(this, payload)
      console.log(sbjmResp)
      if (sbjmResp.resp_status === true) {
        sbjm.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, sbjmResp)
      }
    },
    /**
     * tprepSubjectModuleAdd
     */
    tprepSubjectModuleAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/subjectModule_add")
        }
        else {
          this.showModelSubjectModuleAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at tprepSubjectModuleAdd() and Exception:", err.message)
      }
    },
    /**
     * tprepSubjectModuleEdit
     */
    tprepSubjectModuleEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/subjectModule_edit/" + this.subjectModuleEditObj.subject_module_id)
        }
        else {
          this.subjectModuleEditObj = item
          this.showModelSubjectModuleEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at tprepSubjectModuleEdit() and Exception:", err.message)
      }
    },
    /**
     * showSubjectModuleDeleteDialog
     */
    showSubjectModuleDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelSubjectModuleDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showSubjectModuleDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * tprepSubjectModuleDelete
     */
    async tprepSubjectModuleDelete () {
      try {
        let subjectModuleDelResp = await TPrepSubjectModules.tprepSubjectModuleDelete(this, this.delObj.subject_module_id)
        await ApiResponse.responseMessageDisplay(this, subjectModuleDelResp)

        if (subjectModuleDelResp && !subjectModuleDelResp) {
          this.showModelSubjectModuleDelete = false
          return false
        }

        let index = this.subjectModuleObjList.indexOf(this.delObj)
        this.subjectModuleObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelSubjectModuleDelete = false
      }
      catch (err) {
        console.error("Exception occurred at tprepSubjectModuleDelete() and Exception:", err.message)
      }
    },
    /**
     * closeSubjectModuleAddModal
     */
    closeSubjectModuleAddModal (subjectModuleAddData) {
      try {
        if (subjectModuleAddData) {
          if (this.subjectModuleObjList && this.subjectModuleObjList.length >= 1) {
            let subjectModuleObjLength = this.subjectModuleObjList.length
            let lastId = this.subjectModuleObjList[subjectModuleObjLength - 1]["id"]
            subjectModuleAddData.id = lastId + 1
          }
          else {
            this.subjectModuleObjList = []
            subjectModuleAddData.id = 11111
          }

          subjectModuleAddData.created_on = moment()
          this.subjectModuleObjList.unshift(subjectModuleAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelSubjectModuleAdd = false
        this.tprepSubjectModuleList()
      }
      catch (err) {
        console.error("Exception occurred at closesubjectModuleAddModal() and Exception:", err.message)
      }
    },
    /**
     * intrest List
     */
    async interestList () {
      try {
        let interestObj = {
          "page": this.page
        }
        let interestListResp = await Interests.interestWithCategoryList(this, interestObj)
        if (interestListResp.resp_status) {
          this.interestObjList = interestListResp.resp_data
          this.subjectModuleIntName = this.interestObjList.INTERESTCAT11117
          this.totalRows = interestListResp.resp_data.count
        }
        else {
          this.toastMsg = interestListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at interestList() and Exception:", err.message)
      }
    },
    /**
     * closeSubjectModuleEditModal
     */
    closeSubjectModuleEditModal () {
      try {
        this.showModelSubjectModuleEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeSubjectModuleEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
<style>
.tags{
  border: 1px solid #5128a3;
  color: #5128a3;
  font-size: 16px;
  width: max-content;
  padding: 5px;
  border-radius:5px;
}

</style>
